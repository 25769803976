<template>
  <nut-navbar
      @on-click-back="back"
      @on-click-title="title"
      @on-click-send="send"
  ></nut-navbar>
  <div class="whitebox" width="100%" height="100%">
    <iframe id="ifRamId" width="100%" height="100%"
            :src="shopUrl"></iframe>
  </div>
  <div>
    <!-- 产品列表 -->
<!--    <ul class="piclist" id="scroll">
            <nut-infiniteloading
              containerId="scroll"
              :use-window="false"
              :has-more="hasMore"
              @load-more="loadMore"
            >
              <li v-for="item in proList" :key="item.id">
                <img :src="item.pic" />
                <h3>{{ item.title }}</h3>
                <p>
                  ¥<em class="price">{{ item.nprice }}</em>
                </p>
              </li>
            </nut-infiniteloading>
    </ul>-->

    <!-- 底部菜单 -->
<!--    <nut-tabbar unactive-color="#7d7e80" active-color="#1989fa" :bottom="true">
      <nut-tabbar-item tab-title="店铺首页" icon="dshop"></nut-tabbar-item>
      <nut-tabbar-item
          tab-title="公司简介"
          icon="order"
          to="/about"
      ></nut-tabbar-item>
      <nut-tabbar-item
          tab-title="联系我们"
          icon="service"
          @click="getChat"
      ></nut-tabbar-item>
    </nut-tabbar>-->
  </div>
</template>
<script>
import {reactive, toRefs, ref, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'shop',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const data = reactive({
      proList: [
        {
          title: '华天软件PLM产品全生命周期管理系统',
          shopname: '山大华天软件有想钢的搜嘎设定个',
          nprice: '1299',
          vPrice: '1099',
          gnums: '2',
          pic: 'https://img.zcool.cn/community/01e62a6179f1df11013f22cf587934.jpg@260w_195h_1c_1e_1o_100sh.jpg',
        },
        {
          title: '华天软件PLM产品全生命周期管理系统',
          shopname: '山大华天软件有想钢的搜嘎设定个',
          nprice: '1299',
          vPrice: '1099',
          gnums: '2',
          pic: 'https://img.zcool.cn/community/01e62a6179f1df11013f22cf587934.jpg@260w_195h_1c_1e_1o_100sh.jpg',
        },
        {
          title: '华天软件PLM产品全生命周期管理系统',
          shopname: '山大华天软件有想钢的搜嘎设定个',
          nprice: '1299',
          vPrice: '1099',
          gnums: '2',
          pic: 'https://img.zcool.cn/community/01e62a6179f1df11013f22cf587934.jpg@260w_195h_1c_1e_1o_100sh.jpg',
        },
        {
          title: '华天软件PLM产品全生命周期管理系统',
          shopname: '山大华天软件有想钢的搜嘎设定个',
          nprice: '1299',
          vPrice: '1099',
          gnums: '2',
          pic: 'https://img.zcool.cn/community/01e62a6179f1df11013f22cf587934.jpg@260w_195h_1c_1e_1o_100sh.jpg',
        },
      ],
    })

    function tabSwitch(item, index) {
      console.log(item, index)
    }
    const back = (event) => {
      //返回上一页
      //router.go(-1);
      //返回到优选商城页面
      router.push({ name: 'member', params: { tabMenu: 2 }, query: { innerId: route.query.userId } });
    }
    //   懒加载
    const hasMore = ref(true)
    // const data = reactive({
    //   proList: [],
    // })
    const loadMore = (done) => {
      console.log('data.proList.length>>>' + data.proList.length)
      setTimeout(() => {
        const curLen = data.proList.length
        for (let i = curLen; i < curLen + 10; i++) {
          data.proList.push(`${i}`)
        }
        if (data.proList.length > 30) hasMore.value = false
        done()
      }, 500)
    }
    const init = () => {
      for (let i = 0; i < 10; i++) {
        data.proList.push(`${i}`)
      }
    }
    // 联系客服
    const getChat = () => {
      console.log('联系客服')
    }
    onMounted(() => {
      init()
    })
    //获取商店产品详情列表
    const shopUrl = route.query.shopUrl
    console.log("shop-shopUrl",shopUrl)
    //const shopUrl = 'http://localhost:8081/mobile/enterprise/etpDetail.jsp?innerId=ba3f5b73-29a0-4ce7-9a47-f35dc9af491c&userId=null'
    return {
      vipIcon: require('../assets/img/vip.png'),
      ...toRefs(data),
      loadMore,
      hasMore,
      getChat,
      tabSwitch,
      shopUrl,
      back
    }
  },
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
